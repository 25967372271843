import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from '@mui/material/Dialog'
import { togglePaymentMenu } from '../actions/actions'

class XsollaIframe extends Component {
    constructor() {
        super()
        this.state = {
            // dialog: true
        }
    }

    componentDidMount() {
    }

    render() {
        const handleClickOpen = () => {
            togglePaymentMenu(true)
        }
        const handleClose = () => {
            togglePaymentMenu(false)
        }
        let {paymentDialog, xsollaToken} = this.props
        console.log('payment diag settings', {
            paymentDialog: paymentDialog,
            xsollaToken: xsollaToken
        })
        return (
            <>
                <Dialog
                    maxWidth={false}
                    style={{
                        padding: '10px',
                        width: '100%',
                        height: '100%',
                        zIndex: 100001,
                        // zIndex: 9999999999999999999999999999999999999999
                    }}
                    onClose={handleClose} aria-labelledby="simple-dialog-title" 
                    open={paymentDialog}
                    >   
                    {xsollaToken != null &&
                <iframe 
                style={{
                    minWidth: '650px',
                    minHeight: '600px',
                }}
                // src="https://sandbox-secure.xsolla.com/paystation2/?access_token=svnxTu73Ur7vVV4ls2IJbtQ726OX6ecY"
                src={xsollaToken}
                />
            }
                </Dialog>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        // authenticated: state.stuff.authenticated,
        paymentDialog: state.stuff.paymentDialog
    }
}

export default connect(
    mapStateToProps,
)(XsollaIframe)